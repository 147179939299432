// import React from 'react';
// import Form from 'react-bootstrap/Form';

// const CustomInput = ({ error = false, placeholder, type, value, name, isDisabled, onChange, ...props }) => {
//   return (
//     <div className="">
//       <Form.Control
//         style={{ borderColor: error ? 'red' : '' }}
//         disabled={isDisabled}
//         type={type}
//         placeholder={placeholder}
//         value={value}
//         name={name}
//         id={name}
//         onChange={onChange}
//         autoComplete={'' + Math.random()}
//         {...props}
//       />
//     </div>
//   );
// };

// export default CustomInput;

import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';

const CustomInput = ({
  error = false,
  placeholder,
  type,
  value,
  name,
  isDisabled,
  onChange,
  ...props
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleTogglePicker = (event) => {
    event.preventDefault(); // Prevent any default behavior
    if (type === 'date' && inputRef.current) {
      if (!isPickerOpen) {
        inputRef.current.showPicker(); // Open the date picker
        setIsPickerOpen(true); // Mark picker as open
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(true); // Mark as focused
  };

  const handleBlur = (event) => {
    // Close picker only if the related target is outside the component
    const relatedTarget = event.relatedTarget;
    if (!relatedTarget || !inputRef.current.contains(relatedTarget)) {
      setIsPickerOpen(false); // Close picker when user clicks outside
      setIsFocused(false); // Remove focus state
    }
  };

  return (
    <div
      onClick={handleTogglePicker}
      
    >
      <Form.Control


        style={{
          // border: 'none',
          // outline: 'none',
          // boxShadow: 'none',
          // width: '100%',
          borderColor: error ? 'red' : ''
        }}
        disabled={isDisabled}
        type={type}
        placeholder={type === 'date' ? 'dd/mm/yyyy' : placeholder}
        value={value}
        name={name}
        id={name}
        onFocus={handleFocus}
        onChange={onChange}
        onBlur={handleBlur}
        ref={inputRef}
        autoComplete={'' + Math.random()}
        {...props}
      />
    </div>
  );
};

export default CustomInput;

