import React, { useEffect, useState } from 'react';
//import { addToast } from 'react-toast-notifications'; // Assuming you are using react-toast-notifications
import { useToasts } from 'react-toast-notifications';

import LdcApi from '../../../LdcApis';
const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBookings();
  }, []);


    const { addToast } = useToasts();

  const fetchBookings = async () => {
    setLoading(true); // Start loading
    LdcApi({
      method: 'GET',
      url: 'account/AppointmentCountByDate', // Replace with your actual endpoint
    })
      .then((res) => {
        setLoading(false); // Stop loading
        if (res?.data?.status?.isSuccess) {
          setBookings(res?.data?.data || []); // Assuming the bookings data is in `res.data.data`
        } else {
          addToast('Error fetching bookings', { appearance: 'error' });
        }
      })
      .catch((error) => {
        addToast('There is some error', { appearance: 'error' });
        setLoading(false); // Stop loading
      });
  };

  return (
    <div style={{ padding: '1px', color: 'white' }}>
      <h5 style={{ fontSize: '18px', color: '#fff' }}>Bookings Today</h5>
  
      {loading ? (
        <p>Loading...</p>
      ) : bookings.length > 0 ? (
        bookings.map((booking, index) => {
          // Extract the first name from the username
          const firstName = booking.userName.split(' ')[0];
          return (
            <p
              key={index}
              style={{
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0, // Remove extra space below
                padding: '2px 0', // Add slight padding for clarity
              }}
            >
              <span>{firstName}</span>
              <span>({booking.appointmentCount})</span>
            </p>
          );
        })
      ) : (
        <p>No bookings available</p>
      )}
    </div>
  );
  
  
};

export default BookingList;
