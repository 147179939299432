import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import * as yup from "yup";
import CustomButtons from "../ui-elements/Buttons/CustomButtons";
import CustomDangerButton from "../ui-elements/Buttons/CustomDangerButton";
import LdcApi from "../../LdcApis";
import { useToasts } from "react-toast-notifications";
import Service from "../../modules/Service";
import CustomSelect from "../ui-elements/input/CustomSelect";

const initialValues = {
  salutation: "",
  firstname: "",
  lastName: "",
  mobile: "",
  email: "",
  telephone: "",
  leadSource: "",
  notesComments: "",
  // serviceId: '',
};

const mobileNoRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const validationSchema = yup.object().shape({
  salutation: yup.string().optional("Field is required."),
  firstname: yup.string().required("Field is required."),
  lastName: yup.string().required("Field is required."),
  mobile: yup
    .string()
    .max(12, "Phone No must be at most 12 characters!")
    .min(10, "Phone No must be at least 10 characters!")
    .required("Field is required."),
  email: yup.string().required("Field is required."),
  telephone: yup.string().max(12).optional("Field is required."),
  leadSource: yup.string().required("Field is required."),
  notesComments: yup.string().optional("Field is required."),
  // serviceId: yup.string().optional('Field is required.'),
});

const LeadForm = ({ setShowLead, leadShow, fetchData, allService, bool }) => {
  const { addToast } = useToasts();
  const user = JSON.parse(localStorage.getItem("user"));
  const [servicesData, setServicesData] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [filterService, setFilterService] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [serviceError, setServicesError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [storeFinaldata, setStoreFinalData] = useState({});
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const addLeadSubmit = (values, { resetForm }) => {
    setBtnDisable(true);
    const finalValues = {
      active: true,
      salutation: values.salutation,
      firstName: capitalizeWords(values.firstname),
      lastName: capitalizeWords(values.lastName),
      mobile: values.mobile,
      telephone: values.telephone,
      email: values.email,
      leadSource: values.leadSource,
      notes: values.notesComments, //
      status: "Open",
      leadStatus: "Open Lead",
      leadOwner: user.id,
      serviceId:
        serviceId == "" ? null : servicesData === "" ? null : serviceId,
      category: servicesData == "" ? null : servicesData,
      duplicate: false,
    };

    if (finalValues.category !== null) {
      if (finalValues.serviceId == null) {
        setServicesError(true);
        setBtnDisable(false);
      } else {
        setServicesError(false);

        // setServiceId('');
        // setServicesData('');
        LdcApi({
          method: "POST",
          url: "LeadManagement/UpsertLeadManagement",
          data: finalValues,
        })
          .then((res) => {
            if (res.data.status.isSuccess === true) {
              addToast("Lead Added Successfully", { appearance: "success" });
              resetForm();
              setBtnDisable(false);
              setServiceId("");
              setServicesData("");
              fetchData();
              if (bool) {
                setShowLead(true);
              }
            } else if (
              res.data?.status?.errorMessage ===
              "mobile or email  cannot be duplicate."
            ) {
              setBtnDisable(false);
              // resetForm();
              // addToast('Mobile number cannot be duplicate.', { appearance: 'warning' });
              setShowModal(true);
              setStoreFinalData({ ...finalValues });
            } else {
              setBtnDisable(false);
            }
          })
          .catch((err) => {
            setBtnDisable(false);
          });
      }
    } else {
      setServicesError(false);
      // setServiceId('');
      // setServicesData('');
      LdcApi({
        method: "POST",
        url: "LeadManagement/UpsertLeadManagement",
        data: finalValues,
      })
        .then((res) => {
          if (res.data.status.isSuccess === false) {
            addToast("Lead Added Successfully", { appearance: "success" });
            resetForm();
            setBtnDisable(false);
            setServiceId("");
            setServicesData("");
            fetchData();
            if (bool) {
              setShowLead(false);
            }
          } else if (
            res?.data?.status?.errorMessage ===
            "mobile or email  cannot be duplicate."

            // res.data?.status?.errorMessage == ' mobile cannot be duplicate.'
          ) {
            setBtnDisable(false);
            // addToast('Mobile number cannot be duplicate.', { appearance: 'warning' });
            setShowModal(true);
            setStoreFinalData({ ...finalValues });
          } else {
            setBtnDisable(false);
          }
        })
        .catch((err) => {
          setBtnDisable(false);
        });
    }
  };
  const setShowUser = () => {
    setShowLead(false);
  };

  const serVicesChanges = (e) => {
    setServicesData(e.target.value);
    setServiceId("");
  };
  const serVicesIdChanges = (e) => {
    setServiceId(e.target.value);
  };

  useEffect(() => {
    const allData = allService.filter(
      (el, index) => el.category === servicesData
    );
    setFilterService(allData);
  }, [servicesData]);

  const secTimeLeadSUbmit = () => {
    const finalValues = {
      ...storeFinaldata,
      duplicate: true,
    };
    LdcApi({
      method: "POST",
      url: "LeadManagement/UpsertLeadManagement",
      data: finalValues,
    })
      .then((res) => {
        if (res.data.status.isSuccess === true) {
          addToast("Lead Added Successfully", { appearance: "success" });
          // resetForm();
          setBtnDisable(false);
          setServiceId("");
          setServicesData("");
          fetchData();
          setShowModal(false);
          if (bool) {
            setShowLead(false);
          }
        } else if (
          res.data?.status?.errorMessage ===
          "mobile or email  cannot be duplicate."
        ) {
          setBtnDisable(false);
          // addToast('Mobile number cannot be duplicate.', { appearance: 'warning' });
          setShowModal(false);
          // setStoreFinalData({ ...finalValues });
        } else {
          setBtnDisable(false);
        }
      })
      .catch((err) => {
        setBtnDisable(false);
        setShowModal(false);
      });
  };
  const cancelDuplicate = () => {
    setShowModal(false);
    // setServiceId('');
    // setServicesData('');
  };
  return (
    <div>
      <Card
        className={`p-3 ${!bool && "mt-4"}`}
        style={{
          marginBottom: bool ? "0px" : "1.5rem",
          marginTop: bool && "-1px",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={addLeadSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="">
                    <Form.Label>
                      First Name <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: "capitalize" }}
                      name="firstname"
                      type="text"
                      placeholder="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstname}
                      error={errors.firstname}
                    />
                    {touched.firstname && errors.firstname && (
                      <small className="text-danger error_field">
                        {errors.firstname}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="">
                    <Form.Label>
                      Last Name <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: "capitalize" }}
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      error={errors.lastName}
                    />
                    {touched.lastName && errors.lastName && (
                      <small className="text-danger error_field">
                        {errors.lastName}
                      </small>
                    )}
                  </Form.Group>
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="">
                    <Form.Label>
                      Mobile <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      name="mobile"
                      type="text"
                      placeholder="Mobile Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile}
                      error={errors.mobile}
                    />
                    {touched.mobile && errors.mobile && (
                      <small className="text-danger error_field">
                        {errors.mobile}
                      </small>
                    )}
                  </Form.Group>
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="">
                    <Form.Label>
                      Email <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email}
                    />
                    {touched.email && errors.email && (
                      <small className="text-danger error_field">
                        {errors.email}
                      </small>
                    )}
                  </Form.Group>
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="">
                    <Form.Label>
                      Lead Source <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <select
                      class="form-control"
                      // touched={touched.OrganizationName}
                      id="leadSource"
                      name="leadSource"
                      defaultValue="Select LeadSource"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.leadSource}
                      errors={errors.leadSource}
                      isInvalid={touched.leadSource && !!errors.leadSource}
                      isValid={touched.leadSource && !errors.leadSource}
                    >
                      <option value="">Select Lead Source</option>
                      <option value="Direct Email">Direct Email</option>
                      <option value="Phone">Phone</option>
                      <option value="Website Form">Website Form</option>
                      <option value="WhatClinic">WhatClinic</option>
                      <option value="SocialMedia">Social Media</option>
                    </select>
                    {touched.leadSource && errors.leadSource && (
                      <small className="text-danger error_field">
                        {errors.leadSource}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="">
                    <Form.Label>Notes/Comments </Form.Label>
                    <Form.Control
                      style={{ textTransform: "none" }}
                      name="notesComments"
                      as="textarea"
                      type="text"
                      placeholder="Notes/Comments"
                      // onChange={handleChange}
                      onChange={(e) => {
                        // const transformedValue = e.target.value
                        //   .toLowerCase()
                        //   .replace(/(^\s*\w|[\.\!\?]\s*\w)/g, (c) =>
                        //     c.toUpperCase()
                        //   );

                        const transformedValue = e.target.value.replace(
                          /(^\s*\w|[\.\!\?]\s*\w)/g,
                          (c) => c.toUpperCase()
                        );
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: transformedValue,
                          },
                        });
                      }}
                      onBlur={handleBlur}
                      value={values.notesComments}
                      error={errors.notesComments}
                    />
                    {touched.notesComments && errors.notesComments && (
                      <small className="text-danger error_field">
                        {errors.notesComments}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-1">
                    <Form.Label htmlFor="serviceName">Category</Form.Label>
                    <select
                      class="form-control"
                      // touched={touched.OrganizationName}
                      id="serviceId"
                      name="serviceId"
                      defaultValue="Select LeadSource"
                      onChange={(e) => serVicesChanges(e)}
                      value={servicesData}
                    >
                      <option value="">Select Category</option>
                      <option value="Carpal Tunnel Surgery">
                        Carpal Tunnel Surgery
                      </option>
                      <option value="Dermatology">Dermatology</option>
                      <option value="Facial Aesthetics">
                        Facial Aesthetics
                      </option>
                      <option value="Minor Surgery">Minor Surgery</option>
                      <option value="Other">Other</option>
                      <option value="Video Consultation">
                        Video Consultation
                      </option>
                      <option value="Varicose Veins">Varicose Veins</option>
                    </select>
                  </Form.Group>
                </Col>
                {servicesData === "" ? (
                  ""
                ) : (
                  <Col lg={6}>
                    <Form.Group className="mb-1">
                      <Form.Label htmlFor="serviceName">Service</Form.Label>
                      <select
                        class="form-control"
                        // touched={touched.OrganizationName}
                        id="serviceId"
                        name="serviceId"
                        defaultValue="Select LeadSource"
                        onChange={(e) => serVicesIdChanges(e)}
                        value={serviceId}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        // value={values.serviceId}
                        // errors={errors.serviceId}
                        // isInvalid={touched.serviceId && !!errors.serviceId}
                        // isValid={touched.serviceId && !errors.serviceId}
                      >
                        <option value="">Select Service</option>
                        {filterService.map((el, i) => (
                          <option key={i} value={el.id}>
                            {el.serviceName}
                          </option>
                        ))}
                      </select>
                      {serviceError && (
                        <small className="text-danger error_field">
                          Field is required.
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <div className="d-flex my-4   ">
                <button
                  type="submit"
                  disabled={btnDisable}
                  className="btn px-4 btn-outline-primary"
                >
                  {btnDisable ? "Loading..." : "Save"}
                </button>
                {/* <CustomButtons
                  //  buttonDisabled={addDisable}
                  type="submit "
                  variant="primary"
                  className="outline-"
                  name="Save"
                  buttonDisabled={btnDisable}
                  // disabled={isSubmitting}
                /> */}
                <CustomDangerButton
                  cancelOnClick={setShowUser}
                  variant="danger"
                  name="Cancel"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Card>

      <Modal
        size="xs"
        show={showModal}
        className="newModels"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="">
          <div className="d-flex justify-content-between">
            <h4 className="text-secondary " style={{ fontWeight: "600" }}>
              WARNING!
            </h4>
          </div>
          <h6 className="mt-3">
            This mobile number or email already exists for a lead. Are you sure
            you wish to create a DUPLICATE entry?
          </h6>
          <div className="d-flex mt-4 justify-content-around">
            <Button
              onClick={() => secTimeLeadSUbmit()}
              className="px-5 "
              variant="success"
            >
              Save
            </Button>
            <Button
              onClick={cancelDuplicate}
              className="px-5 "
              variant="danger"
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeadForm;
