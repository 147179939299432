import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Container, Table, Pagination } from 'react-bootstrap'; // ag grid
import { DatePicker, Empty, Skeleton } from 'antd';
import LdcApi from '../../../LdcApis';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import dayjs from 'dayjs';
import { Spinner } from 'react-bootstrap';

const BonusTracker = () => {
  // const [finalDateRange, setFinalDateRange] = useState({
  //   startDate: dayjs().startOf('month').format('YYYY-MM-DD'), // Corrected to use dashes
  //   endDate: dayjs().format('YYYY-MM-DD'), // Corrected to use dashes
  // });
  const [finalDateRange, setFinalDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [eodMonthlyData, setEodMonthlyData] = useState([]);
  //   const [currentPage, setCurrentPage] = useState(1); // Current page
  //   const [itemsPerPage] = useState(5); // Items per page
  //   const totalPages = Math.ceil(eodMonthlyData.length / itemsPerPage);

  const [sortAccending, setSortAccending] = useState(false);
  const [sortData, setSortData] = useState([]);

  const { RangePicker } = DatePicker;
  const [DateRangesss, setDateRangess] = useState({
    startDate: '',
    endDate: '',
  });
  const [monthTotals, setmonthTotals] = useState(0);
  const [monthTotalsConverted, setmonthTotalsConverted] = useState(0);
  // const [monthTotalsConverPercentage, setmonthTotalsConverPercentage] = useState(0);
  const [loading, setLoading] = useState(null);

  const dateFormat = 'DD/MM/YYYY';

  // Get the current date
  const now = dayjs();
  // const now = new Date();

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
  function formatDateToYYYYMMDD(date) {
    // Ensure the parameter is a Date object; if not, use the current date
    const currentDate = date instanceof Date ? date : new Date();
    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  // Get the first day of the current month
  const firstDayOfPresentMonth = dayjs().startOf('month');

  // Set the default value for the RangePicker
  // const defaultValue = [
  //   dayjs(finalDateRange.startDate), // Start date in DD/MM/YYYY format
  //   dayjs(finalDateRange.endDate), // End date in DD/MM/YYYY format
  // ];

  // console.log('Formatted Start Date:', finalDateRange.startDate);
  // console.log(
  //   'Formatted End Date:',
  //   dayjs(finalDateRange.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
  // );

  // // // Set the default value for the RangePicker
  // const defaultValue = [
  //   dayjs(formatDateToYYYYMMDD(threeMonthsAgo)),
  //   dayjs(formatDateToYYYYMMDD(now)),
  // ]; // RangePicker takes an array of two moment objects
  // State to hold the selected date range
  const [selectedRange, setSelectedRange] = useState();

  // Function to get the start and end dates of the current quarter
  // Calculate the current quarter's start and end dates
  const getQuarterDates = () => {
    const currentMonth = dayjs().month(); // Get the current month (0-11)
    let startOfQuarter;
    let endOfQuarter;

    if (currentMonth < 3) {
      // First quarter (Jan - Mar)
      startOfQuarter = dayjs().month(0).startOf('month'); // January 1st
      endOfQuarter = dayjs().month(2).endOf('month'); // March 31st
    } else if (currentMonth < 6) {
      // Second quarter (Apr - Jun)
      startOfQuarter = dayjs().month(3).startOf('month'); // April 1st
      endOfQuarter = dayjs().month(5).endOf('month'); // June 30th
    } else if (currentMonth < 9) {
      // Third quarter (Jul - Sep)
      startOfQuarter = dayjs().month(6).startOf('month'); // July 1st
      endOfQuarter = dayjs().month(8).endOf('month'); // September 30th
    } else {
      // Fourth quarter (Oct - Dec)
      startOfQuarter = dayjs().month(9).startOf('month'); // October 1st
      endOfQuarter = dayjs().month(11).endOf('month'); // December 31st
    }

    return {
      startDate: startOfQuarter.format('YYYY-MM-DD'),
      endDate: endOfQuarter.format('YYYY-MM-DD'),
    };
  };

  // Set the quarter dates as the default value
  const quarterDates = getQuarterDates();
  const defaultValue = [
    dayjs(quarterDates.startDate), // Start date of the current quarter
    dayjs(quarterDates.endDate), // End date of the current quarter
  ];

  //   useEffect(() => {
  //     const quarterDates = getQuarterDates();
  //     setFinalDateRange(quarterDates); // Set the calculated quarter dates as the default date range
  //   }, []);
  const onChange = (date, dateString) => {
    setFinalDateRange({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  useEffect(() => {
    // console.log('defaultValue--->>>', defaultValue);
    // document.title = 'Bonus Tracker- LDC';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setDateRangess({
      ...DateRangesss,
      startDate: moment(firstDay).format('YYYY/MM/DD'),
      endDate: moment(new Date()).format('YYYY/MM/DD'),
    });

    if (quarterDates) {
      const quarterDates = getQuarterDates();
      setFinalDateRange(quarterDates); // Set the calculated quarter dates as the default date range

      // Set default value for the RangePicker using the start and end date from the quarter
      const defaultQuarterRange = [
        dayjs(quarterDates.startDate), // Start date of the current quarter
        dayjs(quarterDates.endDate), // End date of the current quarter
      ];
      setSelectedRange(defaultQuarterRange); // Update the selected range to the quarterly range
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    //  console.log('totalPages', totalPages);
    let fistDate = finalDateRange.startDate.split('/').reverse().join('/');
    let endDate = finalDateRange.endDate.split('/').reverse().join('/');
    // console.log('startDate, endDate', DateRangesss.startDate, endDate);
    // console.log('fistDate, endDate', fistDate, endDate);
    if (finalDateRange.startDate !== '' && DateRangesss.startDate !== '') {
      LdcApi({
        method: 'GET',
        //https://api-ldc.goldenbuzz.in/api/LeadManagement/GetLeadConversion?startdate=2023-05-22&enddate=2023-05-23

        url: `LeadManagement/GetLeadBonusTracker?startDate=${finalDateRange.startDate === '' ? DateRangesss.startDate : fistDate}&endDate=${
          finalDateRange.startDate === '' ? DateRangesss.endDate : endDate
        }`,
        // url: `LeadManagement/GetLeadConversion?startdate=${DateRangesss?.startDate}&enddate=${DateRangesss?.endDate}`,
      })
        .then((res) => {
          if (res?.data.length !== 0) {
            const sortedData = res.data.sort((a, b) => b.total - a.total);
            // console.log('DateRangesss.startDate', DateRangesss.startDate);
            // console.log('fistDate', fistDate);
            setEodMonthlyData(res?.data);

            setLoading(false);
          } else {
            setLoading(null);
          }
        })
        .catch((err) => {
          setLoading(null);
        });
    }
  }, [finalDateRange, DateRangesss]);

  useEffect(() => {
    const monthTotals = eodMonthlyData.reduce(
      (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.handled),
      0
    );
    setmonthTotals(monthTotals);
  }, [eodMonthlyData]);

  useEffect(() => {
    const monthTotalsConverted = eodMonthlyData.reduce(
      (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.converted),
      0
    );
    setmonthTotalsConverted(monthTotalsConverted);
  }, [eodMonthlyData]);

  // useEffect(() => {
  //   const monthTotalsConverPercentage = eodMonthlyData.reduce(
  //     (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.converPercentage),
  //     0
  //   );
  //   setmonthTotalsConverPercentage(monthTotalsConverPercentage);
  // }, [eodMonthlyData]);

  // const sortLead = () => {
  //   // const sortData = eodMonthlyData.reverse();
  //   eodMonthlyData.sort((a, b) => {
  //     let name1 = a.userName.toLowerCase(),
  //       name2 = b.userName.toLowerCase();
  //     if (sortAccending) {
  //       if (name1 < name2) return -1;
  //       if (name1 > name2) return 1;
  //     } else {
  //       if (name1 < name2) return 1;
  //       if (name1 > name2) return -1;
  //     }
  //     return 0;
  //   });
  //   setSortData(eodMonthlyData);
  // };

  const calculateTotals = () => {
    const newPatientsTotal = eodMonthlyData.reduce(
      (sum, el) => sum + el.newPatient,
      0
    );
    const oldPatientsTotal = eodMonthlyData.reduce(
      (sum, el) => sum + el.oldPatient,
      0
    );
    return { newPatientsTotal, oldPatientsTotal };
  };

  const { newPatientsTotal, oldPatientsTotal } = calculateTotals();

  const sortLead = () => {
    const sortedData = [...eodMonthlyData].sort((a, b) => {
      if (sortAccending) {
        return a.converted - b.converted; // Ascending order
      } else {
        return b.converted - a.converted; // Descending order
      }
    });
    setSortData(sortedData);
  };

  useEffect(() => {
    setEodMonthlyData(sortData);
  }, [sortData]);
  // Calculate paginated data
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentData = eodMonthlyData.slice(indexOfFirstItem, indexOfLastItem);

  // Helper function to generate visible page numbers
  //   const generatePageNumbers = () => {
  //     const pages = [];
  //     if (totalPages <= 5) {
  //       // Show all pages if total pages are <= 5
  //       for (let i = 1; i <= totalPages; i++) {
  //         pages.push(i);
  //       }
  //     } else {
  //       if (currentPage > 3) pages.push(1, '...');
  //       const start = Math.max(2, currentPage - 1);
  //       const end = Math.min(totalPages - 1, currentPage + 1);
  //       for (let i = start; i <= end; i++) {
  //         pages.push(i);
  //       }
  //       if (currentPage < totalPages - 2) pages.push('...', totalPages);
  //     }
  //     return pages;
  //   };

  //   // Handle page change
  //   const handlePageChange = (pageNumber) => {
  //     if (pageNumber === '...') return; // Ignore dots
  //     setCurrentPage(pageNumber);
  //   };

  //   // Handle next and previous buttons
  //   const goToPreviousPage = () => {
  //     if (currentPage > 1) setCurrentPage(currentPage - 1);
  //   };

  //   const goToNextPage = () => {
  //     if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  //   };

  //   // Get visible pages logic (only show two numbers at a time)
  //   const visiblePages = () => {
  //     if (totalPages <= 2)
  //       return Array.from({ length: totalPages }, (_, i) => i + 1);
  //     if (currentPage === 1) return [1, 2];
  //     if (currentPage === totalPages) return [totalPages - 1, totalPages];
  //     return [currentPage - 1, currentPage];
  //   };

  return (
    <>
      <Container>
        <div>
          <div style={{ textAlign: 'center' }}>
            <h4>Bonus Tracker - Leaderboard</h4>
          </div>
          <div
            className='d-flex align-items-center my-4'
            style={{ color: 'gray' }}
          >
            <b className='mr-2'>Date Range : </b>
            <RangePicker
              format={dateFormat}
              onChange={onChange}
              defaultValue={defaultValue}

              //   defaultValue={[
              //     dayjs(finalDateRange.startDate),
              //     dayjs(finalDateRange.endDate),
              //   ]}
            />
          </div>
          {loading === true ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <>
                <Skeleton />
              </>
              {/* <Spinner animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner> */}
            </div>
          ) : (
            // <>
            //   <Skeleton />
            // </>
            <>
              {loading === null || false ? (
                <>
                  <Empty />
                </>
              ) : (
                <>
                  {' '}
                  <Table
                    responsive
                    className='table table-bordered'
                    id='bootstrap_table'
                    style={{ marginTop: '2rem' }}
                  >
                    <thead
                      style={{
                        background: '#264ce7',
                        color: '#fff',
                        textAlign: 'center',
                      }}
                      id='bootstrap_table_head table-head2'
                    >
                      <tr>
                        <th
                          scope='col'
                          style={{ minWidth: 200, cursor: 'pointer' }}
                          onClick={() => {
                            setSortAccending(!sortAccending);
                            sortLead();
                          }}
                        >
                          LEAD OWNER{' '}
                          {/* {sortAccending ? <BsArrowDown /> : <BsArrowUp />} */}
                        </th>
                        <th scope='col ' style={{ minWidth: 200 }}>
                          <span id='eod_table_heading'>NEW</span>
                        </th>
                        <th scope='col ' style={{ minWidth: 200 }}>
                          <span id='eod_table_heading'>EXISTING</span>
                        </th>
                        {/* <th
                          scope='col'
                          style={{ minWidth: 200, cursor: 'pointer' }}
                          onClick={() => {
                            setSortAccending(!sortAccending);
                            sortLead();
                          }}
                        >
                          Converted{' '}
                          {sortAccending ? <BsArrowDown /> : <BsArrowUp />}
                        </th> */}
                        <th scope='col ' style={{ minWidth: 200 }}>
                          <span id='eod_table_heading'>TOTAL</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eodMonthlyData.map((el, i) => {
                        // Condition for alternating rows (add red background for handled and converted columns)
                        const isEvenRow = i % 2 === 1;

                        return (
                          <tr key={i} style={{ textAlign: 'center' }}>
                            <td style={{ background: '#FDF86A' }}>
                              <div
                                style={{
                                  textTransform: 'capitalize',
                                  textAlign: 'center',
                                }}
                              >
                                <b
                                  style={{ color: '#373cca' }}
                                  className='bebas-neue-regular'
                                >
                                  {' '}
                                  {el.leadOwner}
                                </b>
                              </div>
                            </td>
                            <td
                              style={{
                                background: isEvenRow ? '#efefef' : '', // Add red background to alternate rows
                              }}
                            >
                              <div>
                                <b>{el.newPatient} </b>
                              </div>
                            </td>
                            <td
                              style={{
                                background: isEvenRow ? '#efefef' : '', // Add red background to alternate rows
                              }}
                            >
                              <div style={{ textTransform: 'capitalize' }}>
                                <b> {el.oldPatient}</b>
                              </div>
                            </td>
                            <td style={{ background: '#FDF86A' }}>
                              <div style={{ textTransform: 'capitalize' }}>
                                <b
                                  style={{ color: '#373cca' }}
                                  className='bebas-neue-regular'
                                >
                                  {' '}
                                  {el.total}
                                </b>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr
                        className='totalconversionn-blue'
                        style={{ color: '#fff', textAlign: 'center' }}
                      >
                        <td>
                          <b>TOTAL</b>
                        </td>
                        <td>
                          <b>{newPatientsTotal}</b>
                        </td>
                        <td>
                          <b>{oldPatientsTotal}</b>
                        </td>
                        <td>
                          <b>{newPatientsTotal + oldPatientsTotal}</b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>{' '}
                  {/* Pagination
                  <Pagination className='justify-content-center'>
                    <Pagination.Prev
                      onClick={goToPreviousPage}
                      disabled={currentPage === 1}
                    />
                    {visiblePages().map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={goToNextPage}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination> */}
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default BonusTracker;
