import { DatePicker, Select, Space } from 'antd';
// import 'antd/dist/antd.css';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Button, Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import ChamberModal from '../../components/Chamber/ChamberModal/ChamberModal';
import MuiSnackBar from '../../components/MuiSnackbar/MuiSnackBar';
import Skelton from '../../components/Skelton/Skelton';
import { useDetectOutsideClick } from '../../helper/useDetechOutsideClick';
import {
  chanelValidate,
  validateEndTime,
  validateStartTime,
} from '../../helper/validation';
import DoctorModu from '../../modules/DoctorModu';
import Room from '../../modules/Room';
import ChamberTable from '../tables/ChamberTable';
import CustomButtons from '../ui-elements/Buttons/CustomButtons';
import CustomDangerButton from '../ui-elements/Buttons/CustomDangerButton';
import CustomInput from '../ui-elements/input/CustomInput';
import CustomSelect from '../ui-elements/input/CustomSelect';
import CustomTime from '../ui-elements/input/TimeInput';
import ChamberCatchupBlocks from '../../components/Chamber/ChamberModal/ChamberCatchupBlocks';
import dayjs from 'dayjs';
const { Option } = Select;

const clinicname = [
  { name: 'London Dermatology Clinic', id: 'London Dermatology Clinic' },
];
const dateFormat = 'DD-MM-YYYY';
const { RangePicker } = DatePicker;
const allStatus = [
  { name: 'Active', id: true },
  { name: 'Inactive', id: false },
];

const allweek = [
  { name: 'Sunday', id: 'Sunday' },
  { name: 'Monday', id: 'Monday' },
  { name: 'Tuesday', id: 'Tuesday' },
  { name: 'Wednesday', id: 'Wednesday' },
  { name: 'Thursday', id: 'Thursday' },
  { name: 'Friday', id: 'Friday' },
  { name: 'Saturday', id: 'Saturday' },
];

const date = new Date();
const getHtmlTimeDate = (date) => {
  const m = date.getMonth() + 1;
  var formattedDate = `${date.getFullYear()}-${m.toString().length === 1 ? '0' + m : m}-${
    date.getDate().toString().length === 1
      ? '0' + date.getDate()
      : date.getDate()
  }`;
  return formattedDate;
};

export const maxDate = getHtmlTimeDate(date);

const chamberInit = {
  id: 0,
  doctorId: '',
  timeStart: '',
  timeEnd: '',
  chamberDate: '',
  endDate: '',
  createdBy: 0,
  active: true,
  roomId: '',
  roomName: '',
  createdOn: date,
  modifiedBy: 0,
  modifiedOn: date,
};

const chamBerErr = {
  doctorId: '',
  timeStart: '',
  timeEnd: '',
  chamberDate: '',
  endDate: '',
  roomId: '',
};

export default function ChamberForm() {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [chamberForm, setChamberForm] = useState(false);
  const [startTimeOpen, setStartTimeOPen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);
  const [chamber, setChamber] = useState(chamberInit);
  const [editChamber, setEditChamber] = useState(chamberInit);
  const [loader, setLoader] = useState(false);
  const [chamberList, setChamberList] = useState([]);
  const [chamberError, setChamberError] = useState(chamBerErr);
  const [editChamberError, setEditChamberError] = useState(chamBerErr);
  const [ldcSnackBar, setLdcSnackBar] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [searchedShifts, setSearchedShifts] = useState({
    doctorId: 0,
    userType: '',
    startDate: '',
    endDate: '',
  });
  const startTimeRef = useRef();
  const endTimeRef = useRef();
  const [catchup, setCatchup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [chamberDetails, setChamberDetails] = useState(null);
  // lifecycle hook
  const users = JSON.parse(localStorage.getItem('user_info'));

  const getDoctorForChamber = () => {
    console.log('users', users.roleID);
    DoctorModu.getAllDoctor((response) => {
      if (response?.data?.status?.isSuccess === false) {
        setDoctorList([]);
      } else {
        let filteredDoctors = response?.data;

        if (users?.roleID === 7) {
          filteredDoctors = filteredDoctors.filter(
            (doctor) => doctor.name == 'Clinic Assistant'
          );
        }

        setDoctorList(filteredDoctors);
      }

      if (response?.status?.request?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
    });
  };

  // const getDoctorForChamber = () => {
  //   console.log('users', users);
  //   DoctorModu.getAllDoctor((response) => {
  //     if (response?.data?.status?.isSuccess === false) {
  //       setDoctorList([]);
  //     } else {
  //       setDoctorList(response?.data);
  //     }
  //     if (response?.status?.request?.status === 401) {
  //       localStorage.clear();
  //       navigate('/');
  //     }
  //   });
  // };
  const getRoomForChamber = () => {
    Room.getAllRooms((response) => {
      if (response?.status === 'success') {
        let filterRoom = response?.data.filter((itm) => {
          return itm.active;
        });

        const tempRoom = filterRoom?.map((item, idx) => {
          return {
            id: item.id,
            name: item.roomName,
          };
        });

        setRoomList(tempRoom);
      }

      if (response?.status?.request?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
    });
  };
  function formatDateToYYYYMMDD(date) {
    // Ensure the parameter is a Date object; if not, use the current date
    const currentDate = date instanceof Date ? date : new Date();
    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 6);
  let newEndDate = new Date(new Date().setDate(new Date().getDate() + 6))
    .toISOString()
    .split('T')[0];

  var startDate = new Date();

  startDate.setDate(startDate.getDate() - 0);
  let newStartDate = moment(startDate).format('YYYY-MM-DD');

  const getChamberList = () => {
    setLoading(true);
    DoctorModu.getSearchedChambers(0, newStartDate, newEndDate, (response) => {
      if (response?.status === 'success') {
        setChamberList(response?.data);
        setLoading(false);
      }
      if (response?.status?.request?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
      //  else {
      //   localStorage.clear();
      //   navigate('/');
      // }
    });
  };

  useEffect(() => {
    getDoctorForChamber();
    getChamberList();
    getRoomForChamber();
  }, []);

  //  onChange

  const onStartTime = (e) => {
    setChamber({
      ...chamber,
      timeStart: e,
    });

    const isValidate = validateStartTime(e, chamber.timeEnd);
    const isEndValidate = validateEndTime(chamber.timeEnd, e);

    if (isValidate) {
      setChamberError({
        ...chamberError,
        timeStart:
          'Start Time should not be greater than  or equal to end time',
      });
    } else {
      setChamberError({
        ...chamberError,
        timeStart: chanelValidate('timeStart', e),
      });

      if (!isEndValidate && chamber.timeEnd !== '') {
        setChamberError({
          ...chamberError,
          timeEnd: '',
          timeStart: '',
        });
      }
    }

    setStartTimeOPen(false);
  };

  const onEndTime = (e) => {
    setChamber({
      ...chamber,
      timeEnd: e,
    });
    const isValidate = validateEndTime(e, chamber.timeStart);
    const isStartValidate = validateStartTime(chamber.timeStart, e);

    if (isValidate) {
      setChamberError({
        ...chamberError,
        timeEnd: 'End Time should not be less than  or equal to start time',
      });
    } else {
      setChamberError({
        ...chamberError,
        timeEnd: chanelValidate('timeEnd', e),
      });
      if (!isStartValidate && chamber.timeStart !== '') {
        setChamberError({
          ...chamberError,
          timeStart: '',
          timeEnd: '',
        });
      }
    }
    setEndTimeOpen(false);
  };

  const onStOutsideClick = () => {
    setStartTimeOPen(false);
  };

  const onEdOutsideClick = () => {
    setEndTimeOpen(false);
  };

  const onChamberChange = (e) => {
    // if (e.target.name === 'doctorId') {
    //   let valuei = JSON.parse(e.target.value);

    //   setChamber({
    //     ...chamber,
    //     userType: valuei.userType,
    //     [e.target.name]: valuei.id
    //   });
    //   setChamberError({
    //     ...chamberError,
    //     [e.target.name]: chanelValidate(e.target.name, valuei.id)
    //   });
    // } else {
    setChamber({
      ...chamber,
      [e.target.name]: e.target.value,
    });
    setChamberError({
      ...chamberError,
      [e.target.name]: chanelValidate(e.target.name, e.target.value),
    });
    // }
  };
  const onChamberEditChange = (e) => {
    setEditChamber({
      ...editChamber,
      [e.target.name]: e.target.value,
    });

    setEditChamberError({
      ...editChamberError,
      [e.target.name]: chanelValidate(e.target.name, e.target.value),
    });
  };

  // submit

  const openLdcSnackBar = (message, type) => {
    const openLdcSnackBarObj = {
      open: true,
      mesage: message,
      type: type,
    };
    setLdcSnackBar(openLdcSnackBarObj);
  };
  const closeSnackBar = () => {
    const ldcSnackbarClose = {
      open: false,
    };
    setLdcSnackBar(ldcSnackbarClose);
  };

  const onUpdateInsertTable = (data) => {
    setLoading(true);
    DoctorModu.addChamber(data, (response) => {
      if (response?.status?.request?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
      if (response?.data.status.errorMessage === 'Slot already booked') {
        setLoading(false);
        setShowPopup(true);
        setChamberDetails(data);
      }
      if (
        response?.data.status.errorMessage ===
        'Shift already exist for appointments'
      ) {
        setLoading(false);
        setShow(false);
        getChamberList();
        addToast('Appointments already exist for the shift ', {
          appearance: 'error',
        });
      }
      if (
        response?.data.status.errorMessage ===
        'Already the doctor booked on same date or in same room'
      ) {
        setLoading(false);
        setShow(false);
        getChamberList();
        addToast('Already the doctor booked on same date or in same room.', {
          appearance: 'error',
        });
      }
      if (
        response?.data.status.errorMessage ===
        'Shift already exist for Blocked/Catch up 15/Catch up 30/Extended Session (Approved)/List Cancelled'
      ) {
        setShow(false);
        setCatchup(true);
        setLoading(false);
      }

      if (
        response?.data?.status?.errorMessage == 'Shift cannot be duplicate.'
      ) {
        setLoading(false);
        addToast('A doctor can have only one shift on a given date!', {
          appearance: 'error',
        });
        // openLdcSnackBar(`A doctor can have only one shit on a given date! `, 'error');
      } else {
        if (response?.data.status.isSuccess) {
          if (response?.status === 'success') {
            setChamber(chamberInit);
            setChamberDetails(null);
            if (data !== 0) {
              if (show) {
                addToast('Shift Updated successfully.', {
                  appearance: 'success',
                });
              } else {
                addToast('Shift Added Successfully.', {
                  appearance: 'success',
                });
              }
            }
            setLoading(false);
            setShow(false);
            getChamberList();
          } else {
            if (data !== 0) {
              addToast('Error Occured While  Shift.', { appearance: 'error' });
              // openLdcSnackBar(`Error Occured While  Chamber `, 'error');
              setLoading(false);
            } else {
              addToast('Error Occured While Adding Shift.', {
                appearance: 'error',
              });
              // openLdcSnackBar(`Error Occured While Adding Chamber `, 'error');
              setLoading(false);
            }
            getChamberList();
          }
        } else if (
          response?.data?.status?.errorMessage ===
          'This room is already occupied by someone else during these hours. Are you sure you wish to still book this shift?'
        ) {
          addToast(
            'This room is already occupied by someone else during these hours. Are you sure you wish to still book this shift?',
            {
              appearance: 'warning',
            }
          );
          setLoading(false);
        } else if (response?.data.status.errorMessage === '') {
          addToast('You cannot edit shifts whose appointments exist.', {
            appearance: 'error',
          });

          // openLdcSnackBar('You cant edit Shifts Whoes Appointment Exists', 'error');
          setLoading(false);
        }
      }
    });
  };

  const onChamberSubmit = (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (chamberError.timeStart?.length > 0 || chamberError.timeEnd?.length > 0)
      return;
    Object.keys(chamber).forEach((name) => {
      const error = chanelValidate(name, chamber[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setChamberError(validationErrors);
      return;
    }

    const modChamber = {
      doctorId: Number(chamber.doctorId),
      timeStart: chamber.timeStart,
      timeEnd: chamber.timeEnd,
      chamberDate: chamber.chamberDate,
      roomId: Number(chamber.roomName),
      active: true,
      userType: chamber.userType,
      validate: false,
      roomAvailable: false,
    };

    onUpdateInsertTable(modChamber);
  };
  const onChamberEdit = (e, validate = false) => {
    e.preventDefault();
    let validationErrors = {};

    if (
      editChamberError.timeStart?.length > 0 ||
      editChamberError.timeEnd?.length > 0
    )
      return;

    Object.keys(editChamber).forEach((name) => {
      const error = chanelValidate(name, editChamber[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setEditChamberError(validationErrors);
      return;
    }

    const modChamber = {
      ...editChamber,
      active: JSON.parse(editChamber.active),
      doctorId: Number(editChamber.doctorId),
      roomId: Number(editChamber.roomName),
      timeStart: editChamber.timeStart,
      timeEnd: editChamber.timeEnd,
      chamberDate: editChamber.chamberDate,
      userType: editChamber.userType,
      validate,
    };

    onUpdateInsertTable(modChamber);
    setCatchup(false);
  };

  const handleEditTableRow = (params) => {
    setShow(true);

    setEditChamber({
      id: params.data.id,
      doctorId: Number(params.data.doctorId),
      timeStart: params.data.timeStart,
      timeEnd: params.data.timeEnd,
      chamberDate: params.data.chamberDate,
      createdBy: params.data.createdBy,
      createdOn: params.data.createdOn,
      modifiedBy: params.data.modifiedBy,
      modifiedOn: params.data.modifiedOn,
      active: params.data.active,
      roomName: params.data.roomId,
    });
  };

  const emptyError = () => {
    let validationErrors = {};
    Object.keys(chamber).forEach((name) => {
      validationErrors[name] = '';
    });
    setChamberError(validationErrors);
    setEditChamberError(validationErrors);
  };

  const onChamberModalCancel = () => {
    emptyError();
    setChamberForm(false);
    setShow(false);
  };

  useDetectOutsideClick(startTimeRef, onStOutsideClick);
  useDetectOutsideClick(endTimeRef, onEdOutsideClick);

  const searchedShiftsForDoctor = () => {
    let shiftsData = {
      doctorId:
        searchedShifts.docterId == undefined ? 0 : searchedShifts?.docterId,
      // userType: searchedShifts.usertype,
      // startDate: searchedShifts.startDate.split('-').reverse().join('-'),
      // endDate: searchedShifts.endDate.split('-').reverse().join('-')
      startDate: searchedShifts.startDate
        ? searchedShifts.startDate.split('-').reverse().join('-')
        : newStartDate,
      endDate: searchedShifts.endDate
        ? searchedShifts.endDate.split('-').reverse().join('-')
        : newEndDate,
    };
    setLoading(true);
    DoctorModu.getSearchedChambers(
      shiftsData.doctorId,
      shiftsData.startDate,
      shiftsData.endDate,
      (response) => {
        if (response?.status === 'success') {
          setChamberList(response?.data);
          setLoading(false);
        }
      }
    );
  };
  const options =
    doctorList &&
    doctorList.map((item) => ({
      id: item.id,
      name: item.name,
      role: `${item.role}`,
    }));
  const closeDeleteTaskPopup = () => {
    setShowPopup(false);
  };
  const submitRequest = () => {
    const data = { ...chamberDetails, roomAvailable: true };
    onUpdateInsertTable(data);
    setShowPopup(false);
  };
  return (
    <div>
      <Button
        variant='outline-primary'
        onClick={() => {
          emptyError();
          setChamberForm(!chamberForm);
        }}
      >
        Add Shift
      </Button>
      <MuiSnackBar ldcSnackBar={ldcSnackBar} onClose={closeSnackBar} />
      <ChamberModal
        show={show}
        handleClose={onChamberModalCancel}
        chamber={editChamber}
        clinicname={clinicname}
        chamberError={editChamberError}
        setEditChamber={setEditChamber}
        setEditChamberError={setEditChamberError}
        doctorList={doctorList}
        allweek={allweek}
        onChamberChange={onChamberEditChange}
        onChamberSubmit={(p) => onChamberEdit(p, false)}
        allStatus={allStatus}
        roomList={roomList}
      />
      <ChamberCatchupBlocks
        catchupBlocks={catchup}
        onChamberSubmit={(p) => onChamberEdit(p, true)}
      />

      {chamberForm && (
        <>
          <Form onSubmit={onChamberSubmit}>
            <Card className='p-3 mt-1'>
              <Row>
                <Col md={4} className='doctorId '>
                  <Form.Group className='mb-3 '>
                    <Form.Label htmlFor='doctorId'> Doctor</Form.Label>
                    <sup>*</sup>

                    <CustomSelect
                      error={chamberError.doctorId ? true : false}
                      name='doctorId'
                      value={chamber.doctorId}
                      onChange={onChamberChange}
                      defaultValue='Select'
                      selectData={options}
                    />
                    <small className='text-danger'>
                      {chamberError.doctorId}
                    </small>
                    {/* <select
                      className="form-control"
                      style={{ borderColor: chamberError.doctorId ? 'red' : '' }}
                      name="doctorId"
                      onChange={onChamberChange}
                    >
                      <option value="">Select</option>
                      {doctorList.map((item, idx) => {
                        let role = `(${item.role})`;
                        return (
                          <option key={idx} value={JSON.stringify(item)}>
                            {item.name} {role}
                          </option>
                        );
                      })}
                    </select> */}
                    {/* <CustomSelect
                      name="doctorId"
                      value={chamber.id}
                      onChange={onChamberChange}
                      defaultValue="Select"
                      selectData={doctorList}
                    /> */}

                    {/* <small className="text-danger">{chamberError.doctorId}</small> */}
                  </Form.Group>
                </Col>

                <Col md={4} ref={startTimeRef}>
                  <Form.Group className='mb-3 '>
                    <Form.Label htmlFor='timeStart'>Time Start</Form.Label>
                    <sup>*</sup>
                    <CustomTime
                      error={chamberError.timeStart ? true : false}
                      value={chamber.timeStart}
                      isDisabled={false}
                      filedNotRed={true}
                      isListOpen={startTimeOpen}
                      setIsListOpen={setStartTimeOPen}
                      onTime={onStartTime}
                      block='end'
                    />
                    <small className='text-danger'>
                      {chamberError.timeStart}
                    </small>
                  </Form.Group>
                </Col>

                <Col md={4} ref={endTimeRef}>
                  <Form.Group className='mb-3 '>
                    <Form.Label htmlFor='timeEnd'>Time End</Form.Label>
                    <sup>*</sup>
                    <CustomTime
                      error={chamberError.timeEnd ? true : false}
                      filedNotRed={true}
                      value={chamber.timeEnd}
                      isDisabled={false}
                      isListOpen={endTimeOpen}
                      setIsListOpen={setEndTimeOpen}
                      onTime={onEndTime}
                      block='end'
                    />
                    <small className='text-danger'>
                      {chamberError.timeEnd}
                    </small>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label htmlFor='chamberDate'> Date</Form.Label>
                    <sup>*</sup>

                    <CustomInput
                      error={chamberError.chamberDate ? true : false}
                      type='date'
                      value={chamber.chamberDate}
                      min={maxDate}
                      name='chamberDate'
                      onChange={onChamberChange}
                    />
                    <small className='text-danger'>
                      {chamberError.chamberDate}
                    </small>
                  </Form.Group>
                </Col>
                <Col md={4} className='roomName'>
                  <Form.Group className='mb-3 '>
                    <Form.Label htmlFor='roomName'>Room</Form.Label>
                    <sup>*</sup>
                    <CustomSelect
                      error={chamberError.roomName ? true : false}
                      name='roomName'
                      value={chamber.roomName}
                      onChange={onChamberChange}
                      defaultValue='Select'
                      selectData={roomList}
                    />
                    <small className='text-danger'>
                      {chamberError.roomName}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <div className='d-flex my-4 p-2 mb-3 '>
                <CustomButtons
                  type='submit'
                  variant='primary'
                  onSubmit={onChamberSubmit}
                  disabled={loading}
                  className='outline-'
                  name='Save'
                />
                <CustomDangerButton
                  variant='danger'
                  cancelOnClick={onChamberModalCancel}
                  name='Cancel'
                />
              </div>
            </Card>
          </Form>
        </>
      )}
      <Row className='mt-2 align-items-center'>
        <Col md={4} className=''>
          <Form.Group className=''>
            <Form.Label htmlFor='doctorId'>Doctor</Form.Label>
            <Select
              defaultValue='Select All'
              showSearch
              style={{
                width: '100%',
              }}
              placeholder='Search to Select'
              optionFilterProp='children'
              onChange={(e) => {
                let data = JSON.parse(e);
                setSearchedShifts({
                  ...searchedShifts,
                  docterId: data ? data?.id : 0,
                  usertype: data?.userType,
                });
              }}
            >
              <Option value={null}> Select All</Option>
              {doctorList.map((item, index) => {
                return (
                  <Option key={index} value={JSON.stringify(item)}>
                    {item.name}&nbsp; {item.role}
                  </Option>
                );
              })}
            </Select>
          </Form.Group>
        </Col>
        <Col md={3} className=''>
          <div className='mb-3'>
            <Form.Label htmlFor='date'>Date Range</Form.Label>
            <Space
              direction='vertical'
              className='custom_input_clock'
              size={12}
              class
            >
              <RangePicker
                onChange={(value, dateString) => {
                  setSearchedShifts({
                    ...searchedShifts,
                    startDate: dateString[0],
                    endDate: dateString[1],
                  });
                }}
                defaultValue={[
                  dayjs(formatDateToYYYYMMDD(new Date())),
                  dayjs(formatDateToYYYYMMDD(endDate)),
                ]}
                format={dateFormat}
              />
            </Space>
          </div>
        </Col>
        <Col md={4} className=''>
          <Button
            onClick={() => searchedShiftsForDoctor()}
            className='mb-0 px-4 py-2'
            type='primary'
          >
            View Shifts
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Skelton />
      ) : (
        <ChamberTable
          roomList={roomList}
          getChamberList={getChamberList}
          chamberTableData={chamberList}
          handleEditTableRow={handleEditTableRow}
        />
      )}
      <Modal show={showPopup}>
        <Modal.Body className=''>
          <div className='d-flex justify-content-between'>
            <h4 className='text-secondary ' style={{ fontWeight: '600' }}>
              WARNING!
            </h4>
          </div>
          <h6 className='mt-3'>
            This room is already in use today are you sure you wish to proceed?
          </h6>
          <div className='d-flex mt-4 justify-content-around'>
            <Button onClick={submitRequest} className='px-5 ' variant='success'>
              Yes
            </Button>
            <Button
              onClick={closeDeleteTaskPopup}
              className='px-5 '
              variant='danger'
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
